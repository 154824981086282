@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
	background: black;
}
.roboto-flex-text {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  /*font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;*/
}

/*// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900*/

.geist-text {
  font-family: "Geist", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* FLEX */
.flexHeightCenter {
	display: flex;
	align-items: center;
}
.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flexBottom {
	display: flex;
	align-items: flex-end;
}
.flexTop {
	display: flex;
	align-items: flex-start;
}
.flexRight {
	display: flex;
	justify-content: right;
/*	align-items: center;*/
}
.flexLeft {
	display: flex;
	justify-content: left;
/*	align-items: center;*/
}
.flexWidthCenter {
	display: flex;
	justify-content: center;
/*	align-items: center;*/
}




.disableSelect{
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;

    pointer-events:none;
}



/*TEXT*/
.text_bigger {
	font-size: 28px;
	color: purple;
}
.text_heading {
	font-size: 22px;
	color: purple;
}
.text_subheading {
	font-size: 18px;
	color: yellow;
}
.text_main {
	font-size: 14px;
	color: red;
}
.text_second {
	font-size: 12px;
	color: green;
}
.text_third {
	font-size: 10px;
	color: orange;
}

/*TEXT GGL*/
.ggl_text_hero {
	font-size: 88px;
}
.ggl_text_heading {
	font-size: 56px;
}
.ggl_text_subheading {
	font-size: 36px;
}
.ggl_text_main_secondary {
	font-size: 20px;
}
.ggl_text_smaller {
	font-size: 16px;
}
.ggl_text_smallest {
	font-size: 14px;
}
.ggl_text_main_color {
	color: rgb(240,243,248);
}
.ggl_text_secondary_color {
	color: rgb(147,157,182);
}

.ggl_border {
	border: 1px solid rgb(28,33,45);
}
.ggl_box_background {
	background-image: linear-gradient(rgb(17,20,29),rgb(10,10,15));
}
.borderRadius {
	border-radius: 14px;
}
.homepage_hero_height {
	height: 600px;
}
.aiUserBackground {
	background: rgb(30,30,42);
}

.noBold {
	font-weight: normal;
}
.ggl_textShadow {
	text-shadow: 0px 0px 14px rgba(0,0,0,0.5);
}

.textInput {
	height: 100%;
	width: 98%;
	padding-left: 2%;
	background: rgb(22,22,32);
	border: 0px;
	color: white;
	font-size: 16px;
}
.textInput:focus {
	border: 0px;
	outline-width: 0px;
}
.textArea {
	padding-top: 4%;
	font-family: "Poppins", sans-serif;
}

.carousel-container {
/*	background: red;*/
	display: flex;
	flex-wrap: nowrap;
/*	overflow: hidden;*/
}
.carousel-item {
	/*height: 20rem;
	min-width: 100%;
	width: 100%;*/
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 500ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.cta_render_button {
	background-image: linear-gradient(40deg,rgb(40,180,230),rgb(40,220,180));
	border-radius: 24px;
/*	height: 40px;*/
/*	width: 200px;*/
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 26px;
	padding-right: 26px;
}







/*COLORS*/
.whiteBackground {
	background: white;
}
.blackBackground {
	background: rgb(9,9,9);
/*	background: red;*/
/*	background-image: linear-gradient(25deg,rgb(11,11,11),rgb(11,17,30));*/
}
.radialColorBackground {
	background-image: radial-gradient(rgb(11,17,30),rgb(8,8,8));
}
.whiteColor {
	color: white;
}
.blackColor {
	color: black;
}
.actionColor {
/*	background-image: linear-gradient(25deg,rgb(90,50,140),rgb(130,90,150));*/
/*	background-image: linear-gradient(-70deg,rgb(50,90,140), rgb(140,50,190));*/
	background-image: linear-gradient(70deg,rgb(90,50,240), rgb(140,150,220));
}
.textGradient {
/*	background-image: linear-gradient(45deg, rgb(60,120,220), rgb(200,20,220));*/
	background-image: linear-gradient(50deg,rgb(60,160,220), rgb(200,20,220));
/*	background-image: linear-gradient(-70deg,rgb(0,70,120), rgb(40,170,190));*/
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-text-fill-color: transparent;
}
.textGradientLighter {
/*	background-image: linear-gradient(45deg, rgb(60,120,220), rgb(200,20,220));*/
	background-image: linear-gradient(50deg,rgb(90,190,250), rgb(230,50,250));
/*	background-image: linear-gradient(-70deg,rgb(0,70,120), rgb(40,170,190));*/
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-text-fill-color: transparent;
}
.textGradientArch {
	background-image: linear-gradient(50deg,rgb(20,190,220), rgb(0,200,140));
	background-size: 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-text-fill-color: transparent;
}






/*POSITIONS*/
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.relative {
	position: relative;
}




/*BUTTON*/
.button {
	padding: 10px;
	border-radius: 6px;
}




.pointer {
	cursor: pointer;
}
.bold {
	font-weight: bold;
}
.pointerNone {
	pointer-events: none;
}
.pointerAuto {
	pointer-events: auto;
}
.fullHeight {
	height: 100%;
}
.fullWidth {
	width: 100%;
}
.textCenter {
	text-align: center;
}
.textShadow {
	text-shadow: 0px 0px 6px rgba(0,0,0,1);
}
.whiteTextShadow {
	text-shadow: 0px 0px 4px white;
}
.halfOpacity {
	opacity: 0.5;
}
.uppercase {
	text-transform: uppercase;
}
.halfWidth {
	width: 50%;
}
.halfHeight {
	height: 50%;
}
.overflow {
	overflow: hidden;
}
.paragraphMarginBottom {
	margin-bottom: 10px;
}
.labelMarginBottom {
	margin-bottom: 6px;
}
.heightSep {
	height: 10px;
}
.noOpacity {
	opacity: 0;
}






/*NAVBAR*/
.navBar {
	background-image: linear-gradient(to top,rgb(17,20,29),rgb(13,14,20));
	border-bottom: 1px solid rgb(24,27,36);
}
.navHamburger {
	height: 2px;
	width: 22px;
	border-radius: 4px;
}
.navHamburgerSpace {
	height: 5px;
}
.navBarTopHamburgerToCross {
	animation: navBarTopHamburgerToCross 250ms;
	transform: rotate(45deg);
	margin-top: 7px;
}
@keyframes navBarTopHamburgerToCross {
	0% {
		transform: rotate(0deg);
		margin-top: 0px;
	}
	100% {
		transform: rotate(45deg);
		margin-top: 7px;
	}
}
.navBarTopHamburgerFromCross {
	animation: navBarTopHamburgerFromCross 250ms;
	transform: rotate(0deg);
	margin-top: 0px;
}
@keyframes navBarTopHamburgerFromCross {
	0% {
		transform: rotate(45deg);
		margin-top: 7px;
	}
	100% {
		transform: rotate(0deg);
		margin-top: 0px;
	}
}
.navBarMiddleHamburgerToCross {
	animation: navBarMiddleHamburgerToCross 250ms;
	opacity: 0;
}
@keyframes navBarMiddleHamburgerToCross {
	0% {
		opacity: 1;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
.navBarMiddleHamburgerFromCross {
	animation: navBarMiddleHamburgerFromCross 250ms;
	opacity: 1;
}
@keyframes navBarMiddleHamburgerFromCross {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.navBarBottomHamburgerToCross {
	animation: navBarBottomHamburgerToCross 250ms;
	transform: rotate(-45deg);
	margin-top: -7px;
}
@keyframes navBarBottomHamburgerToCross {
	0% {
		transform: rotate(0deg);
		margin-top: 0px;
	}
	100% {
		transform: rotate(-45deg);
		margin-top: -7px;
	}
}
.navBarBottomHamburgerFromCross {
	animation: navBarBottomHamburgerFromCross 250ms;
	transform: rotate(0deg);
	margin-top: 0px;
}
@keyframes navBarBottomHamburgerFromCross {
	0% {
		transform: rotate(-45deg);
		margin-top: -7px;
	}
	100% {
		transform: rotate(0deg);
		margin-top: 0px;
	}
}








/*MODAL*/
.modalBack {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.94);
}
.modal {
	background: white;
}







/*SKILLS*/
.skillBar {
	background: red;
	height: 100%;
	opacity: 1;
	animation: skillBar 450ms;

	border-radius: 6px;
}
@keyframes skillBar {
	0% {
		height: 0%;
		opacity: 0;
	}
	100% {
		height: 100%;
		opacity: 1;
	}
}
.skillBarTop {
	height: 0%;
	animation: skillBarTop 450ms;
}
@keyframes skillBarTop {
	0% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}








/*PORTFOLIO*/
.prevNextArrowCircle {
	margin: 10px;
	height: 50px;
	width: 50px;
	border-radius: 30px;
	background: white;
	box-shadow: 0px 0px 14px rgba(0,0,0,0.7);
	transform: scale(1);
}
.prevNextArrowCircle:hover {
	transform: scale(1.04);
}













/*HOVER EFFECTS*/
.hoverSizeUp {
	transform: scale(1);
	transition: 0.1s;
}
.hoverSizeUp:hover {
	transform: scale(1.1);
	transition: 0.1s;
}
.hoverOpacityDown {
	opacity: 1;
	transition: 0.1s;
}
.hoverOpacityDown:hover {
	opacity: 0.6;
	transition: 0.1s;
}
.hoverOpacityUp {
	opacity: 0.6;
	transition: 0.1s;
}
.hoverOpacityUp:hover {
	opacity: 1;
	transition: 0.1s;
}
.imageHoverSizeUp {
	transform: scale(1);
	transition: 0.25s;
}
.imageHoverSizeUp:hover {
	transform: scale(1.02);
	transition: 0.25s;
}
.opacityUpFromZero {
	opacity: 0;
	transition: 0.25s;
}
.opacityUpFromZero:hover {
	opacity: 1;
	transition: 0.25s;
}
.hoverBackgroundLighten {
  background: rgba(255,255,255,0);
  transition: 0.25s;
}
.hoverBackgroundLighten:hover {
  background: rgba(255,255,255,0.05);
  transition: 0.25s;
}
.hoverBackgroundLightenMore {
  background: rgba(255,255,255,0);
  transition: 0.25s;
}
.hoverBackgroundLightenMore:hover {
  background: rgba(255,255,255,0.2);
  transition: 0.25s;
}










/*ANIMATIONS*/
.leftToRightLeft {
	width: 100%;
	animation: leftToRightLeft 450ms;
}
@keyframes leftToRightLeft {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
.leftToRightRight {
	width: 0%;
	animation: leftToRightRight 450ms;
}
@keyframes leftToRightRight {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}
.bottomToTopTop {
	height: 0%;
	animation: bottomToTopTop 450ms;
}
@keyframes bottomToTopTop {
	0% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}
.bottomToTopBottom {
	height: 100%;
	animation: bottomToTopBottom 450ms;
}
@keyframes bottomToTopBottom {
	0% {
		height: 0%;
	}
	100% {
		height: 100%;
	}
}
.fadeIn {
	opacity: 1;
	animation: fadeIn 750ms;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.textAnimateIn {
	animation: textAnimateIn 700ms;
}
@keyframes textAnimateIn {
	0% {
		margin-top: 400px;
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		margin-top: 0px;
		opacity: 1;
	}
}
.textAnimateIn2 {
	animation: textAnimateIn2 900ms;
}
@keyframes textAnimateIn2 {
	0% {
		margin-top: 300px;
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		margin-top: 0px;
		opacity: 1;
	}
}
.textAnimateInBig {
	animation: textAnimateInBig 900ms;
}
@keyframes textAnimateInBig {
	0% {
		margin-top: 700px;
		opacity: 0;
	}
	/*50% {
		opacity: 0;
	}*/
	100% {
		margin-top: 0px;
		opacity: 1;
	}
}
.fadeInslow {
	opacity: 1;
	animation: fadeInslow 1000ms;
}
@keyframes fadeInslow {
	0% {
		opacity: 0;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.fadeInslower {
	opacity: 1;
	transform: scale(1);
	margin-top: 0px;
	animation: fadeInslower 1400ms;
}
@keyframes fadeInslower {
	0% {
		opacity: 0;
		transform: scale(0.8);
		margin-top: 100px;
	}
	/*20% {
		opacity: 0;
	}*/
	100% {
		opacity: 1;
		transform: scale(1);
		margin-top: 0px;
	}
}








/*arrows*/
.arrowRight {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  
  border-left: 20px solid white;
  border-radius: 4px;
}






/*spaces*/
.space_height_10 {
	height: 10px;
}
.space_height_20 {
	height: 20px;
}
.space_height_40 {
	height: 40px;
}
.space_height_60 {
	height: 60px;
}






@media screen and (max-width: 880px) {
	.ggl_text_hero {
		font-size: 50px;
	}
	.ggl_text_heading {
		font-size: 34px;
	}
	.ggl_text_subheading {
		font-size: 24px;
	}
	.ggl_text_main_secondary {
		font-size: 16px;
	}
	.ggl_text_smaller {
		font-size: 14px;
	}
	.ggl_text_smallest {
		font-size: 12px;
	}
	.homepage_hero_height {
		height: 400px;
	}
	.borderRadius {
		border-radius: 8px;
	}
	.space_height_10 {
		height: 7px;
	}
	.space_height_20 {
		height: 12px;
	}
	.space_height_40 {
		height: 24px;
	}
	.space_height_60 {
		height: 40px;
	}
}
@media screen and (max-width: 600px) {
	.ggl_text_hero {
		font-size: 40px;
	}
	.ggl_text_heading {
		font-size: 30px;
	}
	.ggl_text_subheading {
		font-size: 20px;
	}
	.ggl_text_main_secondary {
		font-size: 14px;
	}
	.ggl_text_smaller {
		font-size: 12px;
	}
	.ggl_text_smallest {
		font-size: 10px;
	}
	.homepage_hero_height {
		height: 400px;
	}
	.borderRadius {
		border-radius: 8px;
	}
	.space_height_10 {
		height: 4px;
	}
	.space_height_20 {
		height: 8px;
	}
	.space_height_40 {
		height: 16px;
	}
	.space_height_60 {
		height: 30px;
	}
	.cta_render_button {
		padding-top: 6px;
		padding-bottom: 6px;
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media screen and (max-width: 400px) {
	.ggl_text_hero {
		font-size: 36px;
	}
	.ggl_text_heading {
		font-size: 25px;
	}
	.ggl_text_subheading {
		font-size: 18px;
	}
	.ggl_text_main_secondary {
		font-size: 14px;
	}
	.ggl_text_smaller {
		font-size: 12px;
	}
	.ggl_text_smallest {
		font-size: 10px;
	}
	.homepage_hero_height {
		height: 400px;
	}
	.borderRadius {
		border-radius: 8px;
	}
	.space_height_10 {
		height: 4px;
	}
	.space_height_20 {
		height: 8px;
	}
	.space_height_40 {
		height: 16px;
	}
	.space_height_60 {
		height: 30px;
	}
	.cta_render_button {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 16px;
		padding-right: 16px;
	}
}



